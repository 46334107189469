import React from 'react';
import { Redirect } from '@reach/router';
import { graphql } from 'gatsby';
import { Box, L2Hero, PowerCurrentsBlock, AccordionBlock } from '../ui';
import htmlSerializer from '../prismic/htmlSerializer';
import Layout from '../layout/primary';
import CurrentsHero from '../images/Hero_Currents_new.png';
import TheGripHero from '../images/Hero_TheGRIP.png';

export const query = graphql`
  query newsletter($uid: String!) {
    prismic {
      newsletter(lang: "en-us", uid: $uid) {
        meta_title
        meta_description
        title
        text
        signup_text
        hubspot_form_id
        body {
          ... on PRISMIC_NewsletterBodyIssue {
            fields {
              category
              article {
                ... on PRISMIC_Publication {
                  _meta {
                    uid
                  }
                  title
                  summary
                  image
                }
              }
            }
            primary {
              issue_title
              download_image
              download_file {
                ... on PRISMIC__FileLink {
                  url
                }
                ... on PRISMIC__ExternalLink {
                  url
                }
                ... on PRISMIC__ImageLink {
                  url
                }
              }
            }
          }
        }
      }
    }
  }
`;

export default function({ data }) {
  const doc = data.prismic.newsletter;

  if (!doc) return <Redirect to="/" noThrow />;

  const issues = doc.body.map((issue, index) => ({
    panel: `${issue.primary.issue_title[0].text}${index === 0 ? ' : Latest Issue' : ''}`,
    download: issue.primary.download_image &&
      issue.primary.download_file && {
        src: issue.primary.download_image.url,
        href: issue.primary.download_file.url,
      },
    articles: issue.fields.map(({ category, article }) => ({
      category,
      title: article.title,
      to: `/library/${article._meta.uid}`,
      image: article.image.thumbnail.url,
      imageAlt: article.image.thumbnail.alt,
      excerpt: `${article.summary[0].text.substring(0, 140)}…`,
      linkText: 'Read more',
    })),
  }));

  return (
    <Layout
      title={doc.meta_title}
      description={doc.meta_description}
      image={doc.meta_title === 'Currents' ? CurrentsHero : TheGripHero}
    >
      <L2Hero
        backgroundURL={doc.meta_title === 'Currents' ? CurrentsHero : TheGripHero}
        titleHead={doc.title[0].text}
        titleHeadSize={doc.meta_title === 'Currents' ? '60px' : null}
      />
      <Box>
        <PowerCurrentsBlock
          bodyText={doc.text}
          headerText={doc.title[0].text}
          signupText={doc.signup_text}
          hubspotFormId={doc.hubspot_form_id}
          htmlSerializer={htmlSerializer}
        />
        <AccordionBlock issues={issues} />
      </Box>
    </Layout>
  );
}
